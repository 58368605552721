
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: bold;
    src: url("/public/fonts/woff2/YekanBakhFaNum-VF.woff2");
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: 300;
    src: url("/public/fonts/woff2/YekanBakhFaNum-VF.woff2");
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: normal;
    src: url("/public/fonts/woff2/YekanBakhFaNum-VF.woff2");

}
/** {*/
/*    border: 0;*/
/*    box-sizing: border-box;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/
/*:root {*/
/*    --hue: 223;*/
/*    --bg: hsl(var(--hue),90%,95%);*/
/*    --fg: hsl(var(--hue),90%,5%);*/
/*    --trans-dur: 0.3s;*/
/*    font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));*/
/*}*/
main {
    padding: 1.5em 0;
}
.ip {
    width: 16em;
    height: 8em;
}
.ip__track {
    stroke: hsl(var(--hue),90%,90%);
    transition: stroke var(--trans-dur);
}
.ip__worm1,
.ip__worm2 {
    animation: worm1 2s linear infinite;
}
.ip__worm2 {
    animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue),90%,5%);
        --fg: hsl(var(--hue),90%,95%);
    }
    .ip__track {
        stroke: hsl(var(--hue),90%,15%);
    }
}

/* Animation */
@keyframes worm1 {
    from {
        stroke-dashoffset: 0;
    }
    50% {
        animation-timing-function: steps(1);
        stroke-dashoffset: -358;
    }
    50.01% {
        animation-timing-function: linear;
        stroke-dashoffset: 358;
    }
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes worm2 {
    from {
        stroke-dashoffset: 358;
    }
    50% {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: -358;
    }
}

/* تعریف انیمیشن */
@keyframes tab2Animation {
    0% {
        height: 2rem; /* ارتفاع اولیه */
        margin-left: 180px;
        border-radius: 100px;

        /*transform: translateY(-12rem); !* موقعیت اولیه *!*/
    }
    33% {
        height: 1rem;
        width: 1rem;
        border-radius: 100px;
        transform: translateY(-1rem); /* موقعیت اولیه */
    }
    66% {
        border-radius: 100px;
        height: 1rem;
        width: 1rem;
        transform: translateY(-1rem); /* موقعیت اولیه */
    }
    100% {
        /*margin-left: 100px;*/
        height: 2rem;
        transform: translateY(0); /* حرکت به سمت بالا */
    }
    /*100% {*/
    /*    height: 2rem; !* بازگشت به ارتفاع اولیه *!*/
    /*    transform: translateY(0); !* بازگشت به موقعیت اولیه *!*/
    /*}*/
}

/* کلاس فعال برای انیمیشن */
.tab-bar2-active {
    animation: tab2Animation 1s forwards;
}

/* تعریف انیمیشن */
@keyframes tab3Animation {
    0% {
        height: 2rem; /* ارتفاع اولیه */
        margin-left: 180px;
        border-radius: 100px;

        /*transform: translateY(-12rem); !* موقعیت اولیه *!*/
    }
    33% {
        height: 1rem;
        width: 1rem;
        border-radius: 100px;
        transform: translateY(-1rem); /* موقعیت اولیه */
    }
    66% {
        border-radius: 100px;
        height: 1rem;
        width: 1rem;
        transform: translateY(-1rem); /* موقعیت اولیه */
    }
    100% {
        /*margin-left: 100px;*/
        height: 2rem;
        transform: translateY(0); /* حرکت به سمت بالا */
    }
    /*100% {*/
    /*    height: 2rem; !* بازگشت به ارتفاع اولیه *!*/
    /*    transform: translateY(0); !* بازگشت به موقعیت اولیه *!*/
    /*}*/
}

/* کلاس فعال برای انیمیشن */
.tab-bar3-active {
    animation: tab3Animation 1s forwards;
}


html {
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #3e70a4 grey;
}

@font-face {
    font-family: iranyekan;
    font-style: normal;
    font-weight: normal;
    src: url("/public/fonts/woff2/YekanBakhFaNum-VF.woff2");

}
body, input, select, option {
    font-family: iranyekan !important;
}

.product-page-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f8f9fa;
}

.product-page {
    display: flex;
    max-width: 1200px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.product-images {
    position: relative;
    flex: 1;
    overflow: hidden;
}

.product-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    transition: opacity 0.3s ease-in-out;
}

.image-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    padding: 8px;
    cursor: pointer;
    z-index: 10;
    color: white;
    border-radius: 50%;
}

.image-nav.left {
    left: 10px;
}

.image-nav.right {
    right: 10px;
}

.product-details {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.product-price {
    font-size: 20px;
    color: #de0046;
    margin-bottom: 20px;
}

.product-description {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

.product-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.add-to-cart-button {
    background-color: #4192ef;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-size: 16px;
}

.like-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #de0046;
    font-size: 20px;
}


/*option {*/
    /*font-family: iranyekan, serif;*/
/*}*/



/*@media screen and (min-width: 1024px) {
    [dir=rtl] .lg\:drawer-open > .drawer-toggle ~ .drawer-side {
        pointer-events: auto;
        visibility: visible;
        position: fixed;
        display: block;
        width: auto;
        overscroll-behavior: auto;
    }
}*/

@media screen and (min-width: 768px) {
    [dir="rtl"] .drawer-side > :not(.drawer-overlay) {
        transform: translateX(0%);
    }
    [dir=rtl] .md\:drawer-open > .drawer-toggle ~ .drawer-side {
        pointer-events: auto;
        visibility: visible;
        position: fixed;
        display: block;
        width: auto;
        overscroll-behavior: auto;
    }
}
@media screen and (max-width: 768px) {
    [dir=rtl] .drawer-side>:not(.drawer-overlay){
        transform: translateX(100%);
    }
}

.disabledField {
    cursor: not-allowed;
    --tw-border-opacity: 1;
    border-color: hsl(var(--b2) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--b2) / var(--tw-bg-opacity));
    --tw-text-opacity: 0.2;
}

[dir="rtl"] .select {
    padding-left: 1rem;
    padding-right: 2.5rem;
    font-family: 'iranyekan';
}

.select option {
    font-family: iranyekan, serif !important;
}

@keyframes marquee {
     0% { transform: translateX(100%); }
     100% { transform: translateX(-100%); }
 }

@keyframes shadowRotate {
    0%, 100% {
        box-shadow: 0 10px 15px -3px rgba(59, 130, 246, 0.5), 0 4px 6px -4px rgba(59, 130, 246, 0.5);
    }
    50% {
        box-shadow: 0 10px 15px -3px rgba(16, 185, 129, 0.5), 0 4px 6px -4px rgba(16, 185, 129, 0.5);
    }
}


.group-hover\\:animate-shadow-rotate:hover {
    animation: shadowRotate 3s infinite;
}

@keyframes marquee {
    100% { transform: translateX(-100%); }
    100% { transform: translateX(-100%); }
}

.animate-marquee {
    width: 50px;
    display: inline-block;
    white-space: nowrap;
    /*box-shadow: 0 10px 15px -3px rgba(59, 130, 246, 0.5), 0 4px 6px -4px rgba(59, 130, 246, 0.5);*/
    animation: marquee 8s linear infinite;
}


.animated-border {
    position: relative;
}

.border-animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
}

.border-animation::before {
    content: '';
    position: absolute;
    width: 10px; /* Width of the moving line */
    height: 10px; /* Height of the moving line */
    background-color: gold;
    border-radius: 50%;
    animation: move-line 4s linear infinite;
}

@keyframes move-line {
    0% {
        top: 0;
        left: 0;
    }
    25% {
        top: 0;
        left: 100%;
        transform: translateX(-100%);
    }
    50% {
        top: 100%;
        left: 100%;
        transform: translate(-100%, -100%);
    }
    75% {
        top: 100%;
        left: 0;
        transform: translateY(-100%);
    }
    100% {
        top: 0;
        left: 0;
    }
}



/* Base styles for the checkbox container */
.checkbox-container {
    display: inline-block;
    vertical-align: middle;
    margin: 10px;
    position: relative;
    cursor: pointer;
    user-select: none;
}

/* Hide the default checkbox */
.checkbox-container input[type="checkbox"] {
    display: none;
}

/* Custom checkbox */
.checkbox-container .custom-checkbox {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 6px;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Checkmark */
.checkbox-container .custom-checkbox::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg) scaleY(1) rotateY(0deg);
    transition: transform 0.3s ease;
    opacity: 0;
}

/* Checked state */
.checkbox-container input[type="checkbox"]:checked + .custom-checkbox {
    background-color: #4192EF;
    border-color: #4192EF;
    box-shadow: 0 2px 10px rgba(65, 146, 239, 0.2);
}

.checkbox-container input[type="checkbox"]:checked + .custom-checkbox::after {
    transform: translate(-50%, -50%) rotate(135deg) scaleY(1) rotateX(180deg);
    opacity: 1;
}

/* Hover and focus effects */
.checkbox-container .custom-checkbox:hover {
    border-color: #999;
}

.checkbox-container input[type="checkbox"]:focus + .custom-checkbox {
    box-shadow: 0 0 3px #4192EF;
}

/* Adding a subtle scale animation */
@keyframes scale {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

.checkbox-container input[type="checkbox"]:checked + .custom-checkbox {
    animation: scale 0.3s;
}

@keyframes ripple {
    to {
        transform: scale(4);
        opacity: 0;
    }
}

.button-ripple {
    position: relative;
    overflow: hidden;
}

.button-ripple::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    background: rgba(0, 0, 0, 0.3);
    width: 100px;
    height: 100px;
    pointer-events: none;
}

.button-ripple.active::after {
    animation: ripple 0.6s linear;
}


.product-hover {
    position: relative;
    overflow: hidden;
}

.product-hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: #cecece;
    border-radius: 20%;
    transform: translate(-50%, -50%);
    animation: rotateDot 500ms;
    display: none;
}

.product-hover:hover::after {
    display: block;
}

@keyframes rotateDot {
    0% {
        top: 0;
        left: 0;
    }
    25% {
        top: 0;
        left: 100%;
    }
    50% {
        top: 100%;
        left: 100%;
    }
    75% {
        top: 100%;
        left: 0;
    }
    100% {
        top: 0;
        left: 0;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltip-text {
    visibility: hidden;
    width: 120px; /* عرض جعبه متن */
    background-color: #555; /* رنگ پس‌زمینه جعبه متن */
    color: #fff; /* رنگ متن */
    text-align: center; /* تراز کردن متن */
    border-radius: 6px; /* گوشه‌های گرد */
    padding: 5px 0; /* فاصله داخلی */
    position: absolute;
    z-index: 1; /* مطمئن شوید که متن روی سایر محتوا قرار گیرد */
    bottom: 125%; /* قرار دادن متن بالای عنصر */
    left: 50%;
    margin-left: -60px; /* نیمه عرض جعبه متن برای مرکز کردن */
    opacity: 0; /* مخفی کردن متن در حالت عادی */
    transition: opacity 0.3s; /* انیمیشن برای تغییر شفافیت */
}

.tooltip:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-close-button:hover {
    background-color: #0056b3;
}
